.form_chametz {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 auto 5vw;
  line-height: 2.5rem;
  display: flex;
  flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.form_chametz .divinput_err {
  position: relative;
  width: 40%;
  margin: 10px auto;

}
.form_chametz .divInput {
  position: relative;
  width: 100%;
}

.form_chametz .labelInput {
  border: rgb(196, 196, 196) 0.1vw solid;
  border-radius: 10vw;
  background-color: rgb(255, 255, 255);
  right: 3rem;
  top: -1.5rem;
  position: absolute;
  color: rgb(85, 85, 85);
  line-height: 0.5vw;
  font-weight: 700;
  font-size: 1.5rem;
  width: 40%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_chametz .input {
  height: 5rem;
  border-radius: 8px;
  border: 1px solid rgb(196, 196, 196);
  width: calc(100% - 2.2vw);
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0 1vw;
  text-align: center;
}
option {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 5vw;
}
.form_chametz .input:hover {
  border: 1px solid rgb(0, 0, 0);
}
.form_chametz .input:focus {
  border: 1px solid rgb(0, 0, 0);
}
.form_chametz #width-select {
  width: 100%;
}
.form_chametz .textarea {
  border: 1px solid rgb(196, 196, 196);
  width: calc(100% - 2.2vw);
  border-radius: 8px;
  height: 12rem;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.5vw 1vw;
  overflow: auto;
  background-color: rgb(255, 255, 255);
  scroll-padding: 50px;
  color: black;

}
.form_chametz .div-textarea-Details {
  width: 90%;
  position: relative;
  height: auto;
  margin: 30px auto;

}
.form_chametz .textarea:hover {
  border: 1px solid rgb(0, 0, 0);
}
.form_chametz .textarea:focus {
  border: 2px solid rgb(6, 0, 97);
}
.form_chametz .divErr {
  height: 5rem;
  color: red;
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.form_chametz .div-callBack {
  width: 90%;
  margin-right: 5%;
  text-align: right;

}
.form_chametz button {
  border: 1px solid rgb(196, 196, 196);
  width: calc(50% - 2.2vw);
  border-radius: 8px;
  height: 4rem;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgb(73, 73, 73);
  cursor: pointer;
  margin: 10px auto;
  color: rgb(255, 255, 255);

}
