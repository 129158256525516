.form {
  width: 80%;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 auto 5vw;
  line-height: 2.5rem;
}
.form .divInput {
  position: relative;
  width: 100%;
}

.form .labelInput {
  border: rgb(196, 196, 196) 0.1vw solid;
  border-radius: 10vw;
  background-color: rgb(255, 255, 255);
  right: 3rem;
  top: -1.5rem;
  position: absolute;
  color: rgb(85, 85, 85);
  line-height: 0.5vw;
  font-weight: 700;
  font-size: 1.5rem;
  width: 40%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form .input {
  height: 5rem;
  border-radius: 8px;
  border: 1px solid rgb(196, 196, 196);
  width: calc(100% - 2.2vw);
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0 1vw;
  text-align: center;
}
option {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 5vw;
}
.form .input:hover {
  border: 1px solid rgb(0, 0, 0);
}
.form .input:focus {
  border: 1px solid rgb(0, 0, 0);
}
.form #width-select {
  width: 100%;
}
.form .textarea {
  border: 1px solid rgb(196, 196, 196);
  width: calc(100% - 2.2vw);
  border-radius: 8px;
  height: 12rem;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.5vw 1vw;
  overflow: auto;
  background-color: rgb(255, 255, 255);
  scroll-padding: 50px;
  color: black;
}
.form .div-textarea-Details {
  position: relative;
  height: auto;
}
.form .textarea:hover {
  border: 1px solid rgb(0, 0, 0);
}
.form .textarea:focus {
  border: 2px solid rgb(6, 0, 97);
}
.form .divErr {
  height: 5rem;
  color: red;
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.form .div-callBack {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.form button {
  border: 1px solid rgb(196, 196, 196);
  width: calc(50% - 2.2vw);
  border-radius: 8px;
  height: 4rem;
  font-size: 2rem;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
