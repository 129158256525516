.App {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}

@media only screen and (max-width: 1300px) {
  .App {
    width: 70%;
  }
}
@media only screen and (max-width: 1150px) {
  .App {
    width: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  .App {
    width: 90%;
  }
}
@media only screen and (max-width: 800px) {
  .App {
    width: 100%;
    padding-bottom: 0vw;
  }
}
@media only screen and (max-width: 500px) {
  .App {
    width: 100%;
    padding-bottom: 10vw;
  }
}
