.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: rgb(253, 255, 239);
  z-index: 10;
  display: grid;
  grid-template-columns: 20% 80%;
  border-bottom: 1px solid black;
}

.header .div-logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
}
.header .logo {
  width: 80%;
}

.header .zicaron {
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  margin: 1vh;
  text-align: center;
  font-weight: 700;
}
.header .zicaron_yomi {
  width: 50%;
  height: calc(100px + 0.4vw);
  color: black;
  position: relative;
  /* background-color: aqua; */
}
.header .zicaron_text {
  position: absolute;
  top: 0;
  width: 60%;
  left: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
}
.header .all_zicaron {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  height: calc(100px + 0.4vw);
  margin: 5px auto;
}
.header .zicaron_slider {
  width: 50%;
  height: calc(100px + 0.4vw);
  color: black;
  position: relative;
  /* background-color: rgb(60, 146, 146); */
}

@media only screen and (max-width: 800px) {
  .header .zicaron_text {
    height: calc(90px + 0.4vw);
  }
  .header .zicaron_yomi {
    height: calc(90px + 0.4vw);
  }
  .header .zicaron_slider {
    height: calc(90px + 0.4vw);
  }
  .header .all_zicaron {
    height: calc(90px + 0.4vw);
  }
}
@media only screen and (max-width: 600px) {
  .header .zicaron_text {
    height: calc(80px + 0.4vw);
  }
  .header .zicaron_yomi {
    height: calc(80px + 0.4vw);
  }
  .header .zicaron_slider {
    height: calc(80px + 0.4vw);
  }
  .header .all_zicaron {
    height: calc(80px + 0.4vw);
  }
}
@media only screen and (max-width: 500px) {
  .header .zicaron_text {
    height: calc(70px + 0.4vw);
  }
  .header .zicaron_yomi {
    height: calc(70px + 0.4vw);
  }
  .header .zicaron_slider {
    height: calc(70px + 0.4vw);
  }
  .header .all_zicaron {
    height: calc(70px + 0.4vw);
  }
}
