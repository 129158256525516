.frame {
  height: 100px;
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.frame .frame_background {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #3a3d3e;
  border: 0.2vw solid #de9b72;
  position: relative;
}

.frame .mid-border {
  border: 0.4vw solid #de9b72;
  height: 100%;
  margin: 0.4vw;
}

.frame .inner-border {
  position: relative;
  border: 0.15vw solid #de9b72;
  height: calc(100% - 1.1vw);
  width: calc(100% - 1.1vw);
  margin: 0.4vw;
}
.frame .corner-decoration {
  position: absolute;
  width: 4vw;
  height: calc(50% + 0.3vw);
  margin: -0.3vw;
}

.frame .corner-decoration.corner-left-top {
  left: 0;
  top: 0;
}

.frame .corner-decoration.corner-right-top {
  top: 0;
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.frame .corner-decoration.corner-right-bottom {
  right: 0;
  bottom: 0;
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

.frame .corner-decoration.corner-left-bottom {
  left: 0;
  bottom: 0;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

@media only screen and (max-width: 800px) {
  .frame {
    height: 90px;
  }
}
@media only screen and (max-width: 600px) {
  .frame {
    height: 80px;
  }
}
@media only screen and (max-width: 500px) {
  .frame {
    height: 70px;
  }
}
