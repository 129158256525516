.iconBottom {
  display: none;
}
@media only screen and (max-width: 500px) {
  .iconBottom {
    width: 100%;
    height: 14vh;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .div_icon {
    height: 10vh;
    width: 10vh;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0 0 1vw rgb(124, 124, 124);
    box-shadow: 0 0 1vw rgb(124, 124, 124);
    margin: 2vh 3vh;
  }
  .div_icon svg.MuiSvgIcon-root {
    font-size: 8vh;
    color: green;
    cursor: pointer;
  }
}
