.message {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}

.message-header {
  text-align: center;
  align-items: center;
  color: rgb(0, 0, 0);
  /* border-bottom: 1px solid rgb(180, 180, 180); */
  padding: 2vh 2vh 6vh 2vh;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.7rem;
}

.message .div-form {
  width: 100%;
  display: grid;
  grid-template-columns: 65% 35%;
}

.message .msgmodal {
  margin-top: 10vh;
  padding: 3vw 6vw;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3rem;
  position: relative;
}

.message .timer-wrapper {
  position: absolute;
  top: 0;
  width: calc(100% - 12vw);
  cursor: pointer;
}

.message .timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .message .div-form {
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
  }
}
@media only screen and (max-width: 500px) {
  .message .div-form {
    width: 100%;
    display: flex;
  }
}
