.keeider {
  height: 100%;
  width: 100%;
  color: #fff;
  /* background-color: rgb(187, 187, 187); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
}
