.addNames {
  width: 80%;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 5vh auto 5vw;
  line-height: 2.5rem;
}
.addNames .formAddNames {
  width: 100%;
  margin: 3rem auto;
  height: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.addNames .div_input {
  height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.addNames .input {
  height: 3rem;
  border: none !important;
  border-bottom: 1px solid rgb(0, 0, 0) !important;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.addNames option {
  /* font-size: 1.5rem;
  font-weight: 700; */
  color: rgb(0, 0, 0);
}
select:required:invalid {
  color: rgb(134, 134, 134);
}
.addNames .input:hover {
  border-bottom: 2px solid rgb(0, 0, 0) !important;
}
.addNames .input:focus {
  /* border: none !important; */

  border: 2px solid rgb(0, 0, 0) !important;
}
.addNames .divErr {
  height: 4rem;
  color: red;
  line-height: 2.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.addNames button {
  border: 1px solid rgb(83, 83, 83);
  width: auto;
  border-radius: 8px;
  /* height: 4rem; */
  font-size: 2rem;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  padding: 10px;
  /* margin: 2rem auto; */
}
.addNames .frame {
  width: 100%;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNames .paybox {
  position: absolute;
  color: rgb(255, 255, 255);
  width: 60%;
}
.addNames .paybox a {
  color: rgb(255, 255, 255);
}
