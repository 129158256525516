body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(230, 230, 230) !important;
  /* font-size: 2vw; */
  --vw1: 1vw;
  --vw1-2: 1.2vw;
  --vw1-4: 1.4vw;
  --vw2: 2vw;
  --vw2-2: 2.2vw;
  --vw2-4: 2.4vw;
  --vw2-6: 2.6vw;
}
*,
::after,
::before {
  /* box-sizing: border-box; */
  box-sizing: revert !important;
}
html {
  font-size: 62.5%;
}

@media only screen and (max-width: 1400px) {
  body {
    --vw1: 1.2vw;
    --vw1-2: 1.4vw;
    --vw1-4: 1.6vw;
    --vw2: 2.2vw;
    --vw2-2: 2.4vw;
    --vw2-4: 2.6vw;
    --vw2-6: 2.8vw;
  }
}
@media only screen and (max-width: 1300px) {
  body {
    --vw1: 1.4vw;
    --vw1-2: 1.6vw;
    --vw1-4: 1.8vw;
    --vw2: 2.4vw;
    --vw2-2: 2.6vw;
    --vw2-4: 2.8vw;
    --vw2-6: 3vw;
  }
}
@media only screen and (max-width: 1200px) {
  body {
    --vw1: 1.6vw;
    --vw1-2: 1.8vw;
    --vw1-4: 2vw;
    --vw2: 2.6vw;
    --vw2-2: 2.8vw;
    --vw2-4: 3vw;
    --vw2-6: 3.2vw;
  }
}
@media only screen and (max-width: 1050px) {
  body {
    --vw1: 1.8vw;
    --vw1-2: 2vw;
    --vw1-4: 2.2vw;
    --vw2: 2.8vw;
    --vw2-2: 3vw;
    --vw2-4: 3.2vw;
    --vw2-6: 3.4vw;
  }
}
@media only screen and (max-width: 900px) {
  body {
    --vw1: 2vw;
    --vw1-2: 2.2vw;
    --vw1-4: 2.4vw;
    --vw2: 3vw;
    --vw2-2: 3.2vw;
    --vw2-4: 3.4vw;
    --vw2-6: 3.6vw;
  }
}
@media only screen and (max-width: 800px) {
  body {
    --vw1: 2.2vw;
    --vw1-2: 2.4vw;
    --vw1-4: 2.6vw;
    --vw2: 3.2vw;
    --vw2-2: 3.4vw;
    --vw2-4: 3.6vw;
    --vw2-6: 3.8vw;
  }
}
@media only screen and (max-width: 700px) {
  body {
    --vw1: 2.6vw;
    --vw1-2: 2.8vw;
    --vw1-4: 3vw;
    --vw2: 3.6vw;
    --vw2-2: 3.8vw;
    --vw2-4: 4vw;
    --vw2-6: 4.2vw;
  }
  html {
    font-size: 55%;
  }
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 53%;
  }
}
/* @media only screen and (max-width: 550px) {
  body {
    --vw1: 3vw;
    --vw1-2: 3.2vw;
    --vw1-4: 3.4vw;
    --vw2: 4vw;
    --vw2-2: 4.2vw;
    --vw2-4: 4.4vw;
    --vw2-6: 4.6vw;
  }
} */
@media only screen and (max-width: 450px) {
  html {
    font-size: 50%;
  }

  body {
    --vw1: 3.4vw;
    --vw1-2: 3.6vw;
    --vw1-4: 3.8vw;
    --vw2: 4.4vw;
    --vw2-2: 4.6vw;
    --vw2-4: 4.8vw;
    --vw2-6: 5vw;
  }
}
@media only screen and (max-width: 380px) {
  body {
    --vw1: 3.8vw;
    --vw1-2: 4vw;
    --vw1-4: 4.2vw;
    --vw2: 4.8vw;
    --vw2-2: 5vw;
    --vw2-4: 5.2vw;
    --vw2-6: 5.4vw;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
