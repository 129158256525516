.whatsapp {
  width: calc(100% - 6vw);
  padding: 3vw;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0 auto 5vw;
  line-height: 3rem;
  border-right: 1px solid rgb(180, 180, 180);
}
.whatsapp svg.MuiSvgIcon-root {
  font-size: 6vw;
  color: green;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .whatsapp {
    display: none;
  }
}
